import { MobileContent } from "../../../common/common-styles";
import { LandingTile } from "../../landing-tile/index-v3";
import { ValuePropsGenericCard } from "../../value-props-generic-card";
import { useEffect, useState } from "react";
import { LockscreenCards } from "../../lockscreen-cards";
import { AsSimpleAsGlanceV2 } from "../../as-simple/index-v1";
import { Languages } from "../../languages";
import { BetterWithEveryGlanceV2 } from "../../better-with-every-glance";
import { UserReach } from "../../user-reach";
import { Countries } from "../../countries";
import { Partners } from "../../partners";
import { OemDevices } from "../../oem-devices";
import { ProductPageNews } from "../../../common/product-page-news";
import styled from "styled-components";
import { BannerVideo } from "../../../common/banner-video";
import { HumansOfGlance } from "../../human-of-glance";

export const MobileContainer = styled.div<any>`
  width: 100vw;
  /* padding: 90px 0%; */
  position: relative;
  overflow-x: hidden;
  touch-action: none;
`;

export const UsLockscreenMobile = (props: any) => {
  const [isVisible2, setIsVisible2] = useState(false);

  useEffect(() => {
    setIsVisible2(true);
  }, []);

  return (
    <MobileContent breakPoint="1060px">
      <MobileContainer>
        {props.isIndiaPage ? (
          <BannerVideo
            id="zero"
            videoUrl="https://web.glance-cdn.com/images/glance-website/corp/lockscreen/india/mobile/india-ltv.mp4"
          ></BannerVideo>
        ) : (
          <></>
        )}
        {props.data?.attributes?.landingTileData && (
          <LandingTile data={props.data?.attributes?.landingTileData} />
        )}
        {props.data?.attributes?.valueProps[0] &&
          props.data?.attributes?.valueProps[0].containerClass && (
            <ValuePropsGenericCard
              containerClassName={
                props.data?.attributes?.valueProps[0].containerClass
              }
              textAnimationClassName={
                props.data?.attributes?.valueProps[0].textAnimationClass
              }
              mobileBackgroundImageUrl={
                props.data?.attributes?.valueProps[0].mobileBackground
              }
              desktopBackgroundImageUrl={
                props.data?.attributes?.valueProps[0].desktopBackground
              }
              altText={
                props.data?.attributes?.valueProps[0].desktopLsImageAltText
              }
              largeText={props.data?.attributes?.valueProps[0].firstLine}
              smallText={[
                props.data?.attributes?.valueProps[0].secondLine,
                props.data?.attributes?.valueProps[0].thirdLine,
              ]}
              shouldPin={false}
              backgroundHue={
                props.data?.attributes?.valueProps[0].backgroundHue
              }
              huePosition={props.data?.attributes?.valueProps[0].huePosition}
              id="first"
            />
          )}

        {props.data?.attributes?.valueProps[1] &&
          props.data?.attributes?.valueProps[1].containerClass && (
            <ValuePropsGenericCard
              containerClassName={
                props.data?.attributes?.valueProps[1].containerClass
              }
              textAnimationClassName={
                props.data?.attributes?.valueProps[1].textAnimationClass
              }
              mobileBackgroundImageUrl={
                props.data?.attributes?.valueProps[1].mobileBackground
              }
              desktopBackgroundImageUrl={
                props.data?.attributes?.valueProps[1].desktopBackground
              }
              altText={
                props.data?.attributes?.valueProps[1].desktopLsImageAltText
              }
              largeText={props.data?.attributes?.valueProps[1].firstLine || " "}
              smallText={[
                props.data?.attributes?.valueProps[1].secondLine || " ",
                props.data?.attributes?.valueProps[1].thirdLine || " ",
              ]}
              shouldPin={false}
              backgroundHue={
                props.data?.attributes?.valueProps[1].backgroundHue
              }
              huePosition={props.data?.attributes?.valueProps[1].huePosition}
              id="second"
            />
          )}

        {isVisible2 && (
          <>
            {props.data?.attributes?.valueProps[2] &&
              props.data?.attributes?.valueProps[2].containerClass && (
                <ValuePropsGenericCard
                  containerClassName={
                    props.data?.attributes?.valueProps[2].containerClass
                  }
                  textAnimationClassName={
                    props.data?.attributes?.valueProps[2].textAnimationClass
                  }
                  mobileBackgroundImageUrl={
                    props.data?.attributes?.valueProps[2].mobileBackground
                  }
                  desktopBackgroundImageUrl={
                    props.data?.attributes?.valueProps[2].desktopBackground
                  }
                  altText={
                    props.data?.attributes?.valueProps[2].desktopLsImageAltText
                  }
                  largeText={props.data?.attributes?.valueProps[2].firstLine}
                  smallText={[
                    props.data?.attributes?.valueProps[2].secondLine,
                    props.data?.attributes?.valueProps[2].thirdLine,
                  ]}
                  shouldPin={false}
                  backgroundHue={
                    props.data?.attributes?.valueProps[2].backgroundHue
                  }
                  huePosition={
                    props.data?.attributes?.valueProps[2].huePosition
                  }
                  id="third"
                />
              )}

            {props.data?.attributes?.categoryCards?.length > 0 && (
              <LockscreenCards data={props.data?.attributes?.categoryCards} />
            )}

            {props.data?.attributes?.doesYourPhone && (
              <OemDevices data={props.data?.attributes?.doesYourPhone} />
            )}

            {props.data?.attributes?.valueProps[3] &&
              props.data?.attributes?.valueProps[3].containerClass && (
                <ValuePropsGenericCard
                  containerClassName={
                    props.data?.attributes?.valueProps[3].containerClass
                  }
                  textAnimationClassName={
                    props.data?.attributes?.valueProps[3].textAnimationClass
                  }
                  mobileBackgroundImageUrl={
                    props.data?.attributes?.valueProps[3].mobileBackground
                  }
                  desktopBackgroundImageUrl={
                    props.data?.attributes?.valueProps[3].desktopBackground
                  }
                  altText={
                    props.data?.attributes?.valueProps[3].desktopLsImageAltText
                  }
                  largeText={props.data?.attributes?.valueProps[3].firstLine}
                  smallText={[
                    props.data?.attributes?.valueProps[3].secondLine,
                    props.data?.attributes?.valueProps[3].thirdLine,
                  ]}
                  shouldPin={false}
                  backgroundHue={
                    props.data?.attributes?.valueProps[3].backgroundHue
                  }
                  huePosition={
                    props.data?.attributes?.valueProps[3].huePosition
                  }
                  id="fifth"
                />
              )}

            {props.data?.attributes?.asSimple?.data?.length > 0 && (
              <AsSimpleAsGlanceV2
                data={props.data?.attributes?.asSimple.data}
                fisrtLine={props.data?.attributes?.asSimple.firstLine}
                secondLine={props.data?.attributes?.asSimple.secondLine}
              />
            )}
            {props.data?.attributes?.languageContainer?.languages?.length >
              0 && (
              <Languages
                data={props.data?.attributes?.languageContainer?.languages}
                shouldPin={true}
                pinStart={"top 46px"}
                secondLineText={
                  props.data?.attributes?.languageContainer?.secondLineText
                }
                thirdLineText={
                  props.data?.attributes?.languageContainer?.thirdLineText
                }
              />
            )}

            {props.data?.attributes?.betterWithEveryGlance && (
              <BetterWithEveryGlanceV2
                data={props.data?.attributes?.betterWithEveryGlance}
              />
            )}

            {props.data?.attributes?.poweredByIntelligence && (
              <ValuePropsGenericCard
                containerClassName="powered-by-intelligence"
                textAnimationClassName="pow-by-int"
                mobileBackgroundImageUrl={
                  props.data?.attributes?.poweredByIntelligence.mobileBackground
                }
                desktopBackgroundImageUrl={
                  props.data?.attributes?.poweredByIntelligence
                    .desktopBackground
                }
                altText={
                  props.data?.attributes?.poweredByIntelligence
                    ?.mobileLsImageAltText
                }
                largeText={
                  props.data?.attributes?.poweredByIntelligence.firstLine
                }
                smallText={[
                  props.data?.attributes?.poweredByIntelligence.secondLine,
                  props.data?.attributes?.poweredByIntelligence.thirdLine ||
                    " ",
                ]}
                shouldPin={false}
                backgroundHue={`radial-gradient(50% 50% at 50% 50%, rgba(249, 61, 102, 0.5) 0%, rgba(0, 0, 0, 0) 100%)`}
                huePosition={"0%"}
                hueHeight={"833px"}
                hueWidth={"791px"}
                id="nine"
              />
            )}

            {props.data?.attributes?.userReach && (
              <UserReach data={props.data?.attributes?.userReach} />
            )}

            {props.data?.attributes?.humansOfGlance?.hogData?.length > 0 && (
              <HumansOfGlance
                hogData={props.data?.attributes?.humansOfGlance?.hogData}
                id="human-of-glance"
              />
            )}

            {props.data?.attributes?.countries?.data?.length > 0 && (
              <Countries
                data={props.data?.attributes?.countries.data}
                secondLine={props.data?.attributes?.countries.secondLine}
                thirdLine={props.data?.attributes?.countries.thirdLine}
                shouldPin={true}
                pinStart={"top 46px"}
              />
            )}

            {props.data?.attributes?.oemPartners?.data?.length > 0 && (
              <Partners
                data={props.data?.attributes?.oemPartners.data}
                firstLine={props.data?.attributes?.oemPartners.firstLine}
                secondLine={props.data?.attributes?.oemPartners.secondLine}
                thirdLine={props.data?.attributes?.oemPartners.thirdLine}
                id={props.data?.attributes?.oemPartners.sectionId}
              />
            )}

            {props.data?.attributes?.catchNews?.catchNewsField?.length && (
              <ProductPageNews
                news={props.data?.attributes?.catchNews?.catchNewsField}
                firstLineText={props.data?.attributes?.catchNews?.firstLineText}
                secondLineText={
                  props.data?.attributes?.catchNews?.secondLineText
                }
                id={"fourteen"}
              />
            )}
          </>
        )}
      </MobileContainer>
    </MobileContent>
  );
};
