import styled from "styled-components";

export const BannerVideoContainer = styled.div`
  width: 100vw;
  height: 100vh;
  background: black;
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  video {
    width: 100vw;
    height: 100vh;
    object-fit: cover;
  }
`;

export const LoaderContainer = styled.div`
  width: 100vw;
  height: 100vh;
  top: 0px;
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  background : rgb(27, 16, 24);
  z-index: 999;
  img {
    width: 16vw;
  }
  @media(max-width: 768px){
    img {
      width: 32vw;
    }
  }

`;