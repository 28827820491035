import { useEffect, useRef, useState } from "react";
import gsap from "gsap";
import { ScrollTrigger } from "gsap/dist/ScrollTrigger";
import { BannerVideoContainer, LoaderContainer } from "./styles";
import { HomescreenLoaderConatiner } from "../../homescreen/desktop/styles";
import Image from "next/image";

interface BannerVideoProps {
  videoUrl?: string;
  id?: string;
  controls?: boolean;
}

export const BannerVideo = (props: BannerVideoProps) => {
  const [isLoading, setIsLoading] = useState(true);
  const videoRef: any = useRef<HTMLVideoElement>(null);

  useEffect(() => {
    gsap.registerPlugin(ScrollTrigger);
    ScrollTrigger.create({
      trigger: ".banner-video",
      onEnterBack: () => {
        if (videoRef.current) {
          videoRef.current.pause();
          videoRef.current.currentTime = 0;
          videoRef.current?.play();
        }
      },
    });
  }, []);

  return (
    <BannerVideoContainer id={props.id} className="banner-video">
      {isLoading && (
        <LoaderContainer>
          <Image
            src="/glance_loading.gif"
            alt=""
            width="160"
            height="160"
            loading="eager"
            style={{ objectFit: "cover" }}
          ></Image>
        </LoaderContainer>
      )}
      <video
        src={props.videoUrl}
        playsInline
        autoPlay
        muted
        preload="none"
        ref={videoRef}
        controls={props.controls}
        onPlay={() => {
          setIsLoading(false);
        }}
      />
    </BannerVideoContainer>
  );
};
